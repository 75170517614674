import React, { useState, useEffect } from 'react';
import CardView from 'components/Layout/CardView';
import UploadLogo from 'pages/Settings/BrandingAndLogo/UploadLogo';
import BrandingSection from 'pages/Settings/BrandingAndLogo/Branding';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Divider, Radio, Spin, Switch } from 'antd';
import { getBrandingByTenant } from 'api/branding';
import { setBrandingList, setActiveBrandingTenantId, setCentraliseBranding, setGlobalPoweredByVisibility } from 'redux/actions/branding';
import { useDispatch, useSelector } from 'react-redux';
import { INITIAL_STATE as tenantInitialBrandingState } from 'redux/reducers/tenantReducer';
import { isCurrentPermissionKeyPermitted } from 'utils';

const StyledContainer = styled(Container)`
    padding-left: 0!important;
    padding-right: 0!important;
    z-index: -99;
`;

const BrandingAndLogo = ({
    isReadOnly = true
}) => {
    const tenantState = useSelector(state => state.tenant);
    const brandingState = useSelector(state => state.branding);
    const [isBrandingSectionLoading, setBrandingSectionLoader] = useState(false);
    const [localTenantUniqueId, setLocalTenantUniqueId] = useState(tenantState.uniqueId);
    const dispatch = useDispatch();
    const permissionState = useSelector(state => state.permissions);
    const { uniqueId } = tenantState;

    const [localCentraliseBranding, setLocalCentraliseBranding] = useState(true);
    const [localIsPoweredByShown, setPoweredByVisibility] = useState(true);
    
    useEffect(() => {
        const requestBrandingOnMount = async () => {
            await requestForNewBrandingByTenantUniqueId(localTenantUniqueId);
            setLocalCentraliseBranding(tenantState.branding.centraliseBranding);
            setPoweredByVisibility(tenantState.branding.isPoweredByVisible === undefined || tenantState.branding.isPoweredByVisible === true ? true : false);
            dispatch(setCentraliseBranding(tenantState.branding.centraliseBranding));
    
            dispatch(setActiveBrandingTenantId(localTenantUniqueId));
    
            if (!localCentraliseBranding) {
                requestForNewBrandingByTenantUniqueId(`${tenantState.uniqueId}.patient`);
            }
        }

        requestBrandingOnMount();
    }, []);

    useEffect(() => {
        if (!localCentraliseBranding) {
            requestForNewBrandingByTenantUniqueId(`${tenantState.uniqueId}.patient`);
        } else {
            if (brandingState && brandingState.list.length > 0) {
                const newBrandingState = {...brandingState};

                const newBrandingList = newBrandingState.list.filter(newBrandingStateItem => newBrandingStateItem.tenantUniqueId !== `${tenantState.uniqueId}.patient`);

                dispatch(setActiveBrandingTenantId(newBrandingList[0].tenantUniqueId));
                dispatch(setBrandingList(newBrandingList));
            }
        }
    }, [localCentraliseBranding]);

    const requestForNewBrandingByTenantUniqueId = async (tenantUniqueId) => {
        setBrandingSectionLoader(true);
        let activeBrandingItem = null;

        try {
            const response = await getBrandingByTenant(tenantUniqueId);
            const branding = JSON.parse(response.data.data.theme);
            let newBrandingState = [];

            const activeBranding = brandingState.list.find(brandingItem => {
                return brandingItem.tenantUniqueId === tenantUniqueId
            });
            
            if (!activeBranding) {
                activeBrandingItem = {
                    ...branding,
                    centraliseBranding: localCentraliseBranding,
                    tenantUniqueId
                };

                newBrandingState = brandingState.list.concat(activeBrandingItem);

                dispatch(setBrandingList(
                    newBrandingState
                ));
            }
                
            setBrandingSectionLoader(false);
        } catch (err) {
            console.log(err);

            const activeBranding = brandingState.list.find(brandingItem => {
                return brandingItem.tenantUniqueId === tenantUniqueId
            });

            let newBrandingState = [];
            
            if (!activeBranding) {
                activeBrandingItem = {
                    ...tenantInitialBrandingState.branding,
                    centraliseBranding: localCentraliseBranding,
                    tenantUniqueId
                }
                newBrandingState = brandingState.list.concat(activeBrandingItem);
            
                dispatch(setBrandingList(newBrandingState));
            }

            setBrandingSectionLoader(false);
        }

        return activeBrandingItem;
    }

    const onRadioPortalChange = (e) => {
        let currentTenantUniqueId = '';
        const { value } = e.target;

        if (value === 'patient') {
            currentTenantUniqueId = `${uniqueId}.patient`;
        } else {
            currentTenantUniqueId = `${uniqueId.replace('.patient', '')}`;
        }

        if (brandingState && brandingState.list.length > 0) {
            const newBrandingState = {...brandingState};

            const activeBrandingItem = newBrandingState.list.find(newBrandingStateItem => newBrandingStateItem.tenantUniqueId === currentTenantUniqueId);

            dispatch(setActiveBrandingTenantId(activeBrandingItem.tenantUniqueId));
        }

        setLocalTenantUniqueId(currentTenantUniqueId);
    }

    const handlePoweredBySwitchChange = value => {
        if (value) {
            dispatch(setGlobalPoweredByVisibility(value))
        }
        setPoweredByVisibility(value)
        dispatch(setGlobalPoweredByVisibility(value))
    }

    const handleCentraliseSwitchChange = value => {
        let currentTenantUniqueId = '';
        if (value) {
            currentTenantUniqueId = `${uniqueId.replace('.patient', '')}`;
            dispatch(setCentraliseBranding(value))
            setLocalTenantUniqueId(currentTenantUniqueId);
        }
        setLocalCentraliseBranding(value)
        dispatch(setCentraliseBranding(value))
    }

    let currentBranding = null;

    if (brandingState && brandingState.list.length > 0) {
        currentBranding = brandingState.list.find(brandingListItem => brandingListItem.tenantUniqueId === brandingState.activeBrandingId);
    }

    const brandingAndLogoSections = [
        {
            title: <>
                        <Row>
                            <Col>
                                <h4 className='font-weight-bolder'>Centralise Branding and Logo</h4>
                                <small>Check if you want to apply the same branding and logo for both Admin and Patient Portal.</small>
                            </Col>
                            <Col className='text-right'>
                                {!isReadOnly ? <Switch
                                    className='pull-right'
                                    onChange={handleCentraliseSwitchChange}
                                    defaultChecked={localCentraliseBranding}
                                    checked={localCentraliseBranding}
                                    style={{
                                        marginTop: '10px'
                                    }}
                                /> : localCentraliseBranding.toString()}
                            </Col>
                        </Row>
                        <Divider/>
                    </>,
            content: null,
            cardHeadingStyle: {},
            cardBodyStyle: {
                display: 'none'
            },
            responsiveness: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            }
        },
        {
            title: <>
                        <Row>
                            <Col>
                                <h4 className='font-weight-bolder'>Show/Hide Powered by on Footer</h4>
                                <small>Check if you want to show the powered by section on the footer. Otherwise, uncheck to hide.</small>
                            </Col>
                            <Col className='text-right'>
                                {!isReadOnly ? <Switch
                                    className='pull-right'
                                    onChange={handlePoweredBySwitchChange}
                                    defaultChecked={localIsPoweredByShown}
                                    checked={localIsPoweredByShown}
                                    style={{
                                        marginTop: '10px'
                                    }}
                                /> : localIsPoweredByShown.toString()}
                            </Col>
                        </Row>
                        <Divider/>
                    </>,
            content: null,
            cardHeadingStyle: {},
            cardBodyStyle: {
                display: 'none'
            },
            responsiveness: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            }
        },
        {
            title: !localCentraliseBranding && (<div className='text-center'>
                        <Radio.Group
                            defaultValue='admin'
                            buttonStyle='solid'
                            size='large'
                            onChange={onRadioPortalChange}
                        >
                            <Radio.Button value='admin'>Admin</Radio.Button>
                            <Radio.Button value='patient'>Patient</Radio.Button>
                        </Radio.Group><br/><br/>
                    </div>),
            content: null,
            cardHeadingStyle: {
                marginBottom: '20px'
            },
            cardBodyStyle: {
                display: 'none'
            },
            responsiveness: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            }
        },
        {
            title: <><h1 className='font-weight-bolder'>Logo</h1><br/></>,
            content: currentBranding ? <UploadLogo 
                                            branding={currentBranding}
                                            uploadButtonVisible={isCurrentPermissionKeyPermitted('settings:branding_logo:edit', permissionState)}
                                        /> : null,
            cardHeadingStyle: {},
            cardBodyStyle: {},
            responsiveness: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 2
            }
        },
        {
            title: <><h1 className='font-weight-bolder'>Branding</h1><br/></>,
            content: currentBranding ? <BrandingSection branding={currentBranding} /> : null,
            cardHeadingStyle: {},
            cardBodyStyle: {},
            responsiveness: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 10
            }
        }
    ];

    return  currentBranding ? <Spin spinning={isBrandingSectionLoading}>
                <StyledContainer fluid style={{ minHeight: '200vh' }}>
                    <Row className='mx-1 mb-4 m-h-100v'>
                        <CardView>
                            {brandingAndLogoSections
                                && brandingAndLogoSections.length > 0 
                                && brandingAndLogoSections.map((brandingAndLogoSection, idx) => {
                                    const {
                                        title,
                                        content,
                                        responsiveness,
                                    } = brandingAndLogoSection;

                                    const { xs, sm, md, lg, xl } = responsiveness;

                                    return <Col
                                        key={idx}
                                        xs={xs}
                                        sm={sm}
                                        md={md}
                                        lg={lg}
                                        xl={xl}
                                    >
                                        {title}
                                        {content ? content : null}
                                    </Col>
                            })}
                        </CardView>
                    </Row>
                </StyledContainer>
            </Spin> : null;
};

export default BrandingAndLogo;