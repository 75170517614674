import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import cx from 'classnames';
import {
  Input as AntInput,
  Select as AntSelect,
  Button as AntButton,
  Checkbox,
  Switch,
} from 'antd';
import uuid from 'react-uuid';

import { validateRequiredNode } from '../utils';

import { useNode, useEditor } from '@craftjs/core';
import { FiTrash } from 'react-icons/all';
import EventSettings, { EventControlValues } from '../common/EventSettings';
import { evaluate } from '../common/EventPropertiesEvaluator';

const { Option } = AntSelect;

const Select = ({
  options,
  name,
  withLabel = true,
  labelText = 'Label',
  isRequired,
  value = null,
  error = {
    required: {
      isVisible: false,
      message: 'This field is required',
    },
  },
  isPrefillable = true,
  isSelected = false,
  isEventChild = false,
  isDisabled = false,
}) => {
  const {
    actions: { setProp },
    connectors: { connect, drag },
    eventProperties,
    nodeProps,
    isHovered,
    currentNode,
  } = useNode((state) => ({
    currentNode: state,
    isHovered: state.events.hovered,
    selected: state.events.selected,
    eventProperties: state.data.props.eventProperties,
    nodeProps: state.data.props,
  }));

  const [selectDefaultValue, setSelectDefaultValue] = useState(value);
  const [isFirstTimeValueSet, setFirstTimeValueSet] = useState(true);

  const { actions, query } = useEditor();

  useEffect(() => {
    if (nodeProps && nodeProps.isPrefillable === undefined) {
      setProp((props) => {
        props.isPrefillable = true;
      });
    }
    if (nodeProps && nodeProps.isGridComponent === undefined) {
      setProp((props) => {
        props.isGridComponent = 0;
      });
    }
  }, []);

  useEffect(() => {
    if (value) {
      setSelectDefaultValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (value && isFirstTimeValueSet) {
      setFirstTimeValueSet(false);
    }
  }, [value]);

  useEffect(() => {
    if (
      eventProperties?.evaluateOnPageLoad &&
      isFirstTimeValueSet &&
      !query.getOptions().enabled
    ) {
      evaluate(eventProperties, value, actions, query);
    }
  }, [eventProperties, value]);

  return (
    <div
      className={cx({
        'With-Mark': query.getOptions().enabled,
        'Component-Hovered': isHovered && query.getOptions().enabled,
        isSelected: isSelected && query.getOptions().enabled,
        isEventChild: isEventChild && query.getOptions().enabled,
      })}
      ref={(ref) => connect(drag(ref))}
      style={{
        margin: '10px 0',
      }}
    >
      {isEventChild && query.getOptions().enabled && (
        <div
          style={{
            position: 'absolute',
            top: '-23px',
            left: '8px',
            padding: '5px',
            background: '#ffc400',
            fontSize: '12px',
          }}
        >
          Input_{currentNode.id}
        </div>
      )}
      {withLabel && (
        <>
          <label style={{ marginTop: '0' }}>
            {labelText}{' '}
            {isRequired && <strong className='text-danger'>*</strong>}
          </label>
          <br />
        </>
      )}
      <AntSelect
        className={
          isRequired && error.required.isVisible && 'border border-danger'
        }
        name={name}
        defaultValue={selectDefaultValue}
        value={selectDefaultValue}
        style={{
          width: '100%',
          zIndex: '999',
          //
        }}
        onChange={(value) => {
          evaluate(eventProperties, value, actions, query);
          if (!query.getOptions().enabled) validateRequiredNode(value, setProp);
        }}
        disabled={query.getOptions().enabled || isDisabled}
      >
        {options &&
          options.length > 0 &&
          options.map((val) => {
            return (
              <Option key={val.key} value={val.value}>
                {val.text}
              </Option>
            );
          })}
      </AntSelect>
      {isRequired && error && error.required.isVisible && (
        <span className='validation-message text-danger'>
          {error.required.message}
        </span>
      )}
    </div>
  );
};

const SelectSettings = () => {
  const {
    actions: { setProp },
    options,
    name,
    withLabel,
    labelText,
    isRequired,
    error,
    isPrefillable = true,
    isGridComponent = 0,
  } = useNode((node) => ({
    options: node.data.props.options,
    name: node.data.props.name,
    withLabel: node.data.props.withLabel,
    labelText: node.data.props.labelText,
    isRequired: node.data.props.isRequired,
    error: node.data.props.error,
    isPrefillable: node.data.props.isPrefillable,
    isGridComponent: node.data.props.isGridComponent,
  }));

  return (
    <>
      <Row>
        <Col xs={12}>
          <label className='Form-Builder-Label'>Field name:</label>
          <AntInput
            placeholder='Field name'
            defaultValue={name}
            onChange={(e) => setProp((props) => (props.name = e.target.value))}
          />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={6}>
              <span className='Settings-Switch-Label'>Has Label?</span>
            </Col>
            <Col xs={6} className='text-right'>
              <Switch
                className='Form-Builder-Checkbox'
                checked={withLabel}
                onChange={(e) => setProp((props) => (props.withLabel = e))}
              />
            </Col>
          </Row>
          {withLabel && (
            <>
              <label className='Form-Builder-Label'>Label Text:</label>
              <AntInput
                defaultValue={labelText}
                placeholder='Enter Label Text here'
                onChange={(e) =>
                  setProp((props) => (props.labelText = e.target.value))
                }
              />
              <br />
              <Checkbox
                className='Form-Builder-Checkbox'
                checked={isRequired}
                onChange={(e) =>
                  setProp((props) => (props.isRequired = e.target.checked))
                }
              >
                Required
              </Checkbox>
              <br />
            </>
          )}
          <Row>
            <Col xs={6}>
              <span className='Settings-Switch-Label'>Required</span>
            </Col>
            <Col xs={6} className='text-right'>
              <Switch
                className='Form-Builder-Checkbox'
                checked={isRequired}
                onChange={(e) => setProp((props) => (props.isRequired = e))}
              />
            </Col>
          </Row>
          {isRequired && (
            <>
              <label className='Form-Builder-Label'>
                Required Error message:
              </label>
              <AntInput
                defaultValue={error.required.message}
                placeholder='Enter Required error message'
                onChange={(e) =>
                  setProp(
                    (props) => (props.error.required.message = e.target.value)
                  )
                }
              />
              <br />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label className='Form-Builder-Label'>Options:</label>
          <br />
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <label className='Form-Builder-Label'>Value</label>
        </Col>
        <Col xs={5}>
          <label className='Form-Builder-Label'>Display Text</label>
        </Col>
        <Col xs={2} />
      </Row>
      {options &&
        options.length > 0 &&
        options.map((val) => {
          return (
            <Row key={val.key} style={{ marginBottom: '10px' }}>
              <Col xs={5}>
                <AntInput
                  key={val.key}
                  value={val.value}
                  placeholder='Value'
                  onChange={(e) => {
                    setProp((p) => {
                      const option = p.options.filter(
                        (o) => o.key == val.key
                      )[0];
                      option.value = e.target.value;
                    });
                  }}
                />
              </Col>
              <Col key={val.key} xs={5}>
                <AntInput
                  key={val.key}
                  value={val.text}
                  placeholder='Text'
                  onChange={(e) => {
                    setProp((p) => {
                      const option = p.options.filter(
                        (o) => o.key == val.key
                      )[0];
                      option.text = e.target.value;
                    });
                  }}
                />
              </Col>
              <Col xs={2}>
                <FiTrash
                  onClick={() => {
                    setProp((p) => {
                      const options = p.options.filter((o) => o.key != val.key);
                      p.options = options;
                    });
                  }}
                />
              </Col>
            </Row>
          );
        })}
      <Row>
        <Col>
          <AntButton
            type='link'
            onClick={() => {
              setProp((p) => {
                const suffix = p.options.length + 1;
                p.options.push({
                  key: uuid(),
                  text: `Option ${suffix}`,
                  value: `Value${suffix}`,
                });
              });
            }}
          >
            + Add new Item
          </AntButton>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <span className='Settings-Switch-Label'>Is Field Prefillable?</span>
        </Col>
        <Col xs={6} className='text-right'>
          <Switch
            className='Form-Builder-Checkbox'
            checked={isPrefillable}
            onChange={(e) => setProp((props) => (props.isPrefillable = e))}
          />
        </Col>
      </Row>
    </>
  );
};

const SelectEventSettings = () => {
  return <EventSettings />;
};

Select.craft = {
  displayName: 'Select',
  props: {
    name: 'select',
    options: [
      { key: uuid(), text: 'Option 1', value: 'Value1' },
      { key: uuid(), text: 'Option 2', value: 'Value2' },
      { key: uuid(), text: 'Option 3', value: 'Value3' },
    ],
    eventProperties: {
      conditionalEventEnabled: false,
      action: EventControlValues.actions[0],
      selectedControls: [],
      conditions: [],
    },
    isRequired: false,
    error: {
      required: {
        isVisible: false,
        message: 'This field is required',
      },
    },
    value: null,
    isSelected: false,
    isEventChild: false,
  },
  related: {
    settings: SelectSettings,
    eventSettings: SelectEventSettings,
  },
};

export default Select;
