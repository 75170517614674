import React, {useState} from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {deleteCollateral, getCollateralsDatatable} from "api/collaterals";
import DeleteModal from "components/Modals/DeleteModal";
import {getCollateralURL} from 'api/collaterals';
import {Link} from "react-router-dom";
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CollateralForm from 'pages/Collateral/components/CollateralForm';
import { isCurrentPermissionKeyPermitted } from "utils";
import { useSelector } from 'react-redux';

const CollateralPage = (props) => {
    const [draw, setDraw] = useState(0);

    const [collateralId, setCollateralId] = useState("");
    const [collateral, setCollateral] = useState(null);
    const [collateralFormVisibility, setCollateralFormVisibility] = useState(false);

    const permissionState = useSelector(state => state.permissions);

    const columns = [
        {
            title: <strong>Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (value, record, index) => {
                return <Link to={'#'} onClick={async ()=>{
                    let response = await getCollateralURL(record?.id);
                    // console.log(response) how am i supposed to know if it should be file or url?
                    window.open(response.data, '_blank').focus();
                }}>{value}</Link>;

            },
        },
        {
            title: <strong>Description</strong>,
            dataIndex: "description",
            key: "description",
            sorter: true,
            render:(value, record, index) => {
                return <div style={{
                    width: '30vw',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
                title={value}
                >{value}</div>
            }
        },
        {
            title: <strong>URL</strong>,
            dataIndex: "url",
            key: "url",
            sorter: true,
            render:(value, record, index) => {
                return <div style={{
                    width: '15vw',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
                title={value}
                >{value}</div>
            }
        },
        {
            title: isCurrentPermissionKeyPermitted('resources:options', permissionState) ? <strong>Options</strong> : null,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text, record, value) => {
                const collateralOptions = [
                    {
                        permissionKey: 'resources:options:update',
                        label: 'Update Resource',
                        icon: <EditOutlined />,
                        onClick: async () => {
                            const response = await getCollateralURL(record?.id);
                            setCollateral({...record, fileURL: response?.data});
                            setCollateralFormVisibility(true);
                        }
                    },
                    {
                        permissionKey: 'resources:options:delete',
                        label: 'Delete Resource', 
                        icon: <DeleteOutlined />, 
                        onClick: () => setCollateralId(text)
                    }
                ];

                const permittedCollateralOptions = collateralOptions.filter(collateralOption => (isCurrentPermissionKeyPermitted(collateralOption.permissionKey, permissionState)));

                return isCurrentPermissionKeyPermitted('resources:options', permissionState) 
                && <ActionButtonsDropdown 
                    menus={permittedCollateralOptions}
                />
            },
        },
    ];

    return (
        <>
            <DeleteModal
                entity="resource"
                visible={collateralId}
                setVisibility={setCollateralId}
                entityId={collateralId}
                deleteFunction={deleteCollateral}
                onSuccess={() => setDraw(draw + 1)}
            />
            <CollateralForm 
                title={'Update Resource'}
                visible={collateralFormVisibility}
                collateral={collateral}
                setVisibility={setCollateralFormVisibility}
                onSuccess={() => setDraw(draw + 1)}
            />
            <CRUDDataTable
                draw={draw}
                entity="resource"
                fetchDataFunction={async (params) => {
                    return await getCollateralsDatatable(params);
                }}
                createModal={<CollateralForm title='New Resource' collateral={null}/>}
                isButtonShown={isCurrentPermissionKeyPermitted('resources:new', permissionState)}
                columns={columns}
            />
        </>
    );
};

export default CollateralPage;
