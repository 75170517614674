import React, { useRef, useEffect, useState } from 'react';
import { Typography, Modal, Row, Col, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, ResetButton, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import titleize from 'titleize';

import { createNote } from 'api/messages';

const { Title } = Typography;

const entity = `note`;
const initialValues = {
  note: '',
};

const schema = yup.object().shape({
  // note: yup
  //   .string()
  //   .min(2, "Too short.")
  //   .max(255, "Too long.")
  //   .required("Please enter a note."),
});

const CreateNoteModal = ({
  visible,
  setVisibility,
  icon,
  appointmentId,
  onSuccess,
}) => {
  const formikRef = useRef(null);
  const [noteValue, setNoteValue] = useState('');
  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  useEffect(() => {
    return () => {
      setNoteValue('');
    };
  }, []);

  async function create(values) {
    try {
      message.loading(`Creating ${entity}...`, 0);
      const res = await createNote(appointmentId, { note: noteValue });
      message.destroy();
      onSuccess();
      message.success(`${titleize(entity)} successfully created.`);
      setVisibility(false);
      setNoteValue('');
    } catch (error) {
      message.destroy();
      message.error(error.response.data.message);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
      setNoteValue('');
    }
  }

  return (
    <Modal
      title={
        <Title
          level={4}
          className='mb-0'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {icon}
          {`New ${titleize(entity)}`}
        </Title>
      }
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => create(values)}
        render={() => (
          <Form layout='vertical' colon={false}>
            {/* <Form.Item label="Note" name="note" className="mb-4">
              <Input.TextArea
                name="note"
                autoSize={{minRows: 3, maxRows: 10}}
              />
            </Form.Item> */}
            <textarea
              // autoSize={{
              //   minRows: 5,
              //   maxRows: 7,
              // }}
              value={noteValue}
              style={{ width: '100%' }}
              onChange={(e) => setNoteValue(e.target.value)}
            />
            <Row gutter={4} className='d-flex justify-content-end'>
              <Col>
                <ResetButton>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton>Create</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateNoteModal;
