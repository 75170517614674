import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {useHistory, useParams} from 'react-router';
import CreateEditPublicForm from 'pages/forms/CreateEditPublicForm';

import {
    HiPencil, 
    BsCheck, 
    MdClose,
    FiEye,
    FiEyeOff,
    MdContentCopy,
    FiSettings,
    CgUndo,
    CgRedo,
    CgErase,
    VscSaveAll,
    CgSoftwareUpload,
    CgArrowLeftO
} from 'react-icons/all';
import { 
    Input,
    message,
    Popconfirm,
    Switch,
    Popover,
    Button,
    Spin,
    Modal
} from 'antd';

import styled from 'styled-components';

import moment from 'moment';

import lz from 'lzutf8';

import {
    useEditor
} from '@craftjs/core';
import {updateContent} from '../../api/forms';

import FormEditor from 'pages/FormBuilder/Editor';
import { FormModes } from 'utils/enums';

const nonValuedControls = ['Container', 'Card', 'Panel', 'Column', 'Button', 'Text'];

const PencilIcon = styled(HiPencil)`
    color: #0170FE;

    &:hover {
        cursor: pointer;
    }
`;

const Topbar = ({ formData }) => {
    const history = useHistory();
    const {
        query,
        actions,
        nodes,
        selectedNode
    } = useEditor((state) => ({
        nodes: state.nodes,
        selectedNode: state.events.selected
    }));
    
    const [formNameValue, setFormNameValue] = useState('');
    const [currentFormData, setCurrentFormData] = useState(formData);
    const [isPreviewEnabled, setPreviewEnabled] = useState(false);
    const [isFormPrefillable, setFormPrefillability] = useState(false);
    const [formPrefillabilityDuration, setFormPrefillabilityDuration] = useState(0);
    const [currentSelectedNode, setCurrentSelectedNode] = useState(null);
    const [querySerialized, setQuerySerialized] = useState(null);
    const { id } = useParams();
    
    const [jsonContent, setJsonContent] = useState(null);
    const [previewFormData, setPreviewFormData] = useState({});
    const [editFormModalVisible, setEditFormModalVisibility] = useState(false);

    useEffect(() => {
        if (formData) {
            setCurrentFormData(formData);
        }
    }, [formData]);

    useEffect(() => {
        if (isPreviewEnabled) {
            setJsonContent(querySerialized);
        }
    }, [isPreviewEnabled, querySerialized])

    useEffect(() => {
        if (selectedNode) {
            setCurrentSelectedNode(selectedNode);
        }
    }, [selectedNode])

    useEffect(() => {
        if (formData && formData.name) {
            setFormNameValue(formData.name);
            setFormPrefillability(formData.prefillEnabled);
            setFormPrefillabilityDuration(formData.prefillExpiryDays);
        }
    }, [formData.name]);

    useEffect(() => {
        if (query.serialize()) {
            setQuerySerialized(query.serialize());
        }
    }, [query]);

    const clearNodesValues = () => {
        Object.keys(nodes).map(serializeNodeKey => {
            const nodeControl = query.getSerializedNodes()[serializeNodeKey];

            if (!nonValuedControls.includes(nodeControl.displayName)) {
                if (nodeControl.props.hasOwnProperty('value')) {
                    actions.setProp(serializeNodeKey, props => {
                        if (nodeControl.props.displayName === 'Input' && nodeControl.props.type === 'datetime') props.value = moment();
                        if (nodeControl.props.displayName === 'Select') props.value = props.options && props.options.length > 0 && props.options[0].value;
                        if (nodeControl.props.displayName === 'Input' && nodeControl.props.type === 'text') props.value = '';

                        nodeControl.props.error.required.isVisible = false;
                    });
                }
            }
        })
    };

    const update = async (name, status) => {
        let successMessage = '';
        let errorMessage = '';
        const newStatus = status ? status : currentFormData.status;
        const newName = name ? name : currentFormData.name;

        const querySerialize = querySerialized;

        clearNodesValues();

        const json = lz.encodeBase64(lz.compress(querySerialize));
            
        if (newStatus === 'DRAFT') {
            successMessage = 'Form successfully saved.';
            errorMessage = 'Error occurred while trying to save the document';
        }

        if (newStatus === 'LIVE') {
            successMessage = 'Form successfully published.';
            errorMessage = 'Error occurred while trying to publish the document';
        }
        
        try {
            const response = await updateContent({
                id: id,
                externalAsmCode: currentFormData.externalAsmCode,
                name: newName,
                content: json,
                designUniqueId: currentFormData.designUniqueId,
                status: newStatus,
                prefillExpiryDays: formPrefillabilityDuration,
                prefillEnabled: isFormPrefillable
            });

            message.success(successMessage);

            if (response.data) {
                setCurrentFormData(response.data);
            }

            if (newStatus === 'LIVE' ) {
                setTimeout(() => {
                    history.push('/forms');
                }, 2000);
            }
        } catch (error) {
            if(error?.response?.data?.message) {
                message.error(error.response.data.message);
            } else {
                message.error(errorMessage);
            }
        }
    }

    const getParsedSerializedNodeById = (id) => {
        const currentNode = query.getSerializedNodes()[id];
        const parsedSerializedNode = query.parseSerializedNode(currentNode).toNode();

        return parsedSerializedNode;
    }

    const duplicate = async () => {
        if (currentSelectedNode) {
            const parsedSerializedNode = getParsedSerializedNodeById(currentSelectedNode);
            const copyParsedSerializedNode = {...parsedSerializedNode};
            let newChildNodes = [];
            
            if (copyParsedSerializedNode.data.parent) {
                const parsedParentNode = getParsedSerializedNodeById(parsedSerializedNode.data.parent);
                
                if (copyParsedSerializedNode.data.nodes && copyParsedSerializedNode.data.nodes.length > 0) {
                    copyParsedSerializedNode.data.nodes.map(nodeId => {
                        const parsedSerializedChildNode = getParsedSerializedNodeById(nodeId);
                        const newParsedSerialized = {...parsedSerializedChildNode};
                        
                        newChildNodes.push(newParsedSerialized);
                    });
                }

                let currentSelectedNodeIndex = parsedParentNode.data.nodes ? parseInt(parsedParentNode.data.nodes.findIndex(node => node === currentSelectedNode)) + 1 : 0;

                copyParsedSerializedNode.data.nodes = [];

                actions.setProp(currentSelectedNode, props => props.isSelected = false);
                await actions.add(copyParsedSerializedNode, parsedSerializedNode.data.parent, currentSelectedNodeIndex);
                
                const updatedParentNode = getParsedSerializedNodeById(parsedSerializedNode.data.parent);

                if (updatedParentNode && updatedParentNode.data.nodes && updatedParentNode.data.nodes.length > 0) {
                    const newlyAddedNodeId = updatedParentNode.data.nodes[currentSelectedNodeIndex];

                    if (newChildNodes && newChildNodes.length > 0) {
                        newChildNodes.map(async (newChildNode, idx) => {
                            console.log(newChildNode);
                            await actions.add(newChildNode, newlyAddedNodeId, idx);
                        });
                    }

                    actions.selectNode(newlyAddedNodeId);
                    setCurrentSelectedNode(newlyAddedNodeId);
                }
            }
        }
    }

    const save = () => {
        update(null, 'DRAFT');
    }

    const publish = () => {
        update(null, 'LIVE');
    }

    const clearAllElements = () => {
        Object.keys(nodes).map((serializeNodeKey) => {
            const currentNode = query.node(serializeNodeKey);
            if (currentNode.isDeletable()) {
                actions.setHidden(serializeNodeKey, true);
            }
        });
    }

    const previewForm = () => {
        if (!isPreviewEnabled) {
            setPreviewEnabled(true);
        }
    }

    const topToolbarItems = [
        {
            icon: (className) => {
                return (
                    <Popover
                        placement="bottomRight"
                        title={<h4 className='font-weight-bolder mt-3'>Settings</h4>}
                        content={
                        <div
                            style={{
                                minHeight: '50px'
                            }}
                        >
                            <Row style={{ marginTop: '10px', minWidth: '500px' }}>
                                <Col xs={10} md={10}>
                                    Is Form Prefillable?
                                </Col>
                                <Col className='text-right' xs={2} md={2}>
                                    <Switch
                                        defaultChecked={formData?.prefillEnabled}
                                        onChange={(value) => {
                                            setFormPrefillability(value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            {isFormPrefillable && <Row style={{ marginTop: '10px' }}>
                                <Col xs={10} md={10}>
                                    Days before prefillability expires
                                </Col>
                                <Col xs={2} md={2}>
                                    <Input
                                        className='text-center'
                                        defaultValue={formData?.prefillExpiryDays}
                                        value={formPrefillabilityDuration}
                                        onChange={(e) => setFormPrefillabilityDuration(e.target.value)}
                                    />
                                </Col>
                            </Row>}
                        </div>}
                        trigger="click"
                    >
                        <Button 
                            style={{
                                height: '100%',
                                background: 'transparent',
                                border: 'none',
                                padding: '0'
                            }}
                        >
                            <div style={{ marginTop: '-5px' }}>
                                <FiSettings className={className} />
                                <div className='' style={{ marginTop: '5px', fontSize: '90%' }}>Settings</div>
                            </div>
                        </Button>
                    </Popover>
                )
            }
        },
        {
            icon: (className) => {
                return <MdContentCopy className={className} />
            },
            name: 'Duplicate',
            action: () => duplicate()
        },
        {
            icon: (className) => {
                let currentIcon = <FiEye className={className} />;

                if (isPreviewEnabled) {
                    currentIcon = <FiEyeOff className={className} />;
                }

                return currentIcon;
            },
            name: isPreviewEnabled ? 'Unpreview' : 'Preview',
            action: () => previewForm(),
        },
        {
            icon: (className) => <CgUndo className={className} />,
            name: 'Undo',
            action: () => actions.history.undo(),
        },
        {
            icon: (className) => <CgRedo className={className} />,
            name: 'Redo',
            action: () => actions.history.redo(),
        },
        {
            icon: (className) => (
                <Popconfirm 
                    placement="bottom" 
                    title='Are you sure you want to clear the canvas?' 
                    okText="Yes" 
                    cancelText="No"
                    onConfirm={clearAllElements}
                >
                    <CgErase className={className} />
                </Popconfirm>),
            name: 'Clear',
        },
        {
            icon: (className) => <VscSaveAll className={className} />,
            name: 'Save Draft',
            action: () => save(),
        },
        {
            icon: (className) => <CgSoftwareUpload className={className} />,
            name: 'Publish',
            action: () => publish(),
        }
    ];

    return (
        <>
            <CreateEditPublicForm
                mode={FormModes.EDIT}
                visible={editFormModalVisible}
                setVisibility={setEditFormModalVisibility}
                formData={currentFormData}
                setFormData={setCurrentFormData}
            />
            <Modal
                title='Form Preview'
                visible={isPreviewEnabled}
                footer={
                    <Button
                        type='default'
                        onClick={() => {
                            setPreviewEnabled(false);
                        }}
                    >
                        Close
                    </Button>
                }
                bodyStyle={{
                    padding: 0,
                }}
                onCancel={() => setPreviewEnabled(false)}
                keyboard={false}
                maskClosable={false}
                style={{ 
                    top: 20,
                }}
                width={'60vw'}
                destroyOnClose
            >
                <FormEditor 
                    isEditor={false}
                    jsonContent={jsonContent}
                    formData={previewFormData}
                    formSubmissionContent={null}
                />
            </Modal>
            <div className='Form-Builder-Topbar'>
                <Container fluid>
                    <Row>
                        <Col 
                            xs={4} 
                            md={8}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <h4 
                                    className='Form-Builder-Top-Title Title-Icon' 
                                    onClick={() => history.push('/forms') }
                                >
                                    <CgArrowLeftO
                                        style={{
                                            marginTop: '5px'
                                        }}
                                        size={'3em'}
                                    />
                                </h4>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <h4 className='Form-Builder-Top-Title Main'>
                                        <strong>Form</strong> Builder
                                    </h4>
                                    <span className='Form-Builder-Name'>
                                        <>
                                            <span>{currentFormData.name}</span>&nbsp;&nbsp;
                                            <PencilIcon onClick={() => {
                                                setEditFormModalVisibility(true);
                                            }} />
                                        </> 
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', 
                                alignContent: 'flex-end'
                            }} 
                            xs={4}
                            md={4}
                        >
                            {topToolbarItems && topToolbarItems.map((topToolbarItem, idx) => {
                                const { icon, name, action } = topToolbarItem;
                                
                                return (
                                    <div className='Topbar-Toolbar-Items text-center' key={idx} onClick={() => { if (action) action() }}>
                                        {icon('Toolbar-Options')}
                                        <br /><small>{name}</small>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default Topbar;
