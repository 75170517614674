import React, { useEffect, useState } from 'react';
import AntdDataTable from 'components/DataTable/AntdDataTable';
import { Button, Card, Space, message } from 'antd';
import titleize from 'titleize';
import DataTableFilter from './DataTableFilter';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {setDataTableFilters} from "redux/actions/dataTable";

const Action = styled(Space)`
  display: none;
  @media (min-width: 500px) {
    display: flex;
  }
`;

const MobileAction = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: block;
    margin: 3px 0 20px 0;
    & button {
      width: 100%;
      display: block;
      margin: 5px;
    }
  }
`;

const CRUDDataTable = (props) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(props.columns);
  const [draw, setDraw] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    order: props.sortOrder ? props.sortOrder : props.columns[0].dataIndex,
    direction: 'ASC',
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: props.pageSize ?? 10,
  });
  const [total, setTotal] = useState(0);
  const customFilters = props.customFilters;
  const { title, continuousFetchInterval } = props;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const cachedFilter = useSelector(state => state.dataTable[props.cachedFilterKey]);
  const getFilterValue = () => {
    if (props.cachedFilterEnabled && cachedFilter && props.cachedFilterAsDefault) {
      return cachedFilter;
    }
    return columns.reduce((filter, col, i) => {
      if (col.filter) {
        if (!col.filter.paramName)
          throw Error("Property 'paramName' of Object 'filter' is required");

        filter[col.filter.paramName] = {
          label: col.filter.label,
          value: col.filter.defaultValue,
        };
      }

      return filter;
    }, {});
  }
  const [filters, setFilters] = useState(getFilterValue());

  useEffect(() => {
    if(props.cachedFilterEnabled && filters) {
      const _cachedFilter = {};
      _cachedFilter[props.cachedFilterKey] = filters;
      dispatch(setDataTableFilters(_cachedFilter));
    }

    if(filters.search !== search?.value) {
      setSearch(filters.search.value);
    }
  }, [filters])


  const PollingFetch = ({ fetchData, continuousFetchInterval }) => {
    useEffect(() => {
      let fetch = setInterval(fetchData, continuousFetchInterval);

      return () => clearInterval(fetch);
    });

    return null;
  };

  useEffect(() => {
    if (props.data && props.data.data) {
      setData(props.data.data);
      setTotal(props.data.recordsTotal);
      setDraw(props.data.draw);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.draw >= 1) {
      fetchData(filters, sort, pagination);
    }
  }, [props.draw]);

  useEffect(() => {
    if (customFilters !== undefined) {
      fetchData(filters, sort, pagination);
    }
  }, [customFilters]);

  useEffect(() => {
    fetchData(filters, sort, pagination);
  }, [filters, sort, pagination]);

  const fetchData = async (filters, sort, pagination) => {
    setLoading(true);
    try {
      let params = {
        page: pagination.current - 1,
        draw: draw,
        start: (pagination.current - 1) * pagination.pageSize,
        length: pagination.pageSize,
        sortColumn: sort.order,
        sortDirection: sort.direction,
      };

      if (customFilters !== undefined) {
        customFilters.forEach((filter, index) => {
          params[filter.paramName] = filter.value;
        });
      }

      if (filters)
        Object.keys(filters).forEach((p) => {
          if (filters[p].beforeRequest) {
            const val = filters[p].beforeRequest(filters[p].value);
            if (val) Object.keys(val).forEach((vp) => (params[vp] = val[vp]));
          } else if(filters[p].value) {
            params[p] = filters[p].value;
          }
        });

      if (props.fetchDataFunction) {
        const res = await props.fetchDataFunction(params);
        setData(res.data.data);
        setTotal(res.data.recordsTotal);
        setColumns(columns);
        setDraw(res.data.draw);
        setLoading(false);
      } else {
        setColumns(columns);
        setLoading(false);
      }
    } catch (error) {
      console.log(error); // the api returns it under fled name 'message'
      message.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!search) return;
    const newFilters = { ...filters };
    if (newFilters.search) {
      newFilters.search.value = search;
    } else {
      newFilters.search = { value: search };
    }

    setFilters(newFilters);
  }, [search]);

  function handleChange(pagination, filters, sorter, extra) {
    setPagination(pagination);
    setSort({
      order: sorter.field ? sorter.field : columns[0].dataIndex,
      direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
    });
  }

  const [isCreateModalVisible, setCreateModalVisibility] = useState(false);

  // Added undefined to the condition to still show the button if it is not declared on the props, some sort of default prop value.
  let button =
    props.isButtonShown || props.isButtonShown === undefined ? (
      props.button !== undefined ? (
        React.cloneElement(props.button, {
          onClick: () => {
              if (!props.buttonPreventDefault) {
                setCreateModalVisibility(true);
              }
          },
        })
      ) : (
        <Button type='primary' onClick={() => setCreateModalVisibility(true)}>
          New{' '}
          {props.entity === 'collateral' ? 'Resource' : titleize(props.entity)}
          {props.createModal}
        </Button>
      )
    ) : null;

  const extra = (
    <>
      {props.actions || props.tabs ? (
        <Action>
          {props.actions}
          {button}
        </Action>
      ) : (
        <Space>
          {props.actions}
          {button}
        </Space>
      )}
      <DataTableFilter
        filterState={{ filters, setFilters }}
        filtersConfig={columns
          .filter((col) => col.filter)
          .map((col) => col.filter)}
        style={{ float: 'right' }}
      />
    </>
  );

  return (
    <>
      {props.createModal && React.cloneElement(props.createModal, {
        visible: isCreateModalVisible,
        setVisibility: setCreateModalVisibility,
        onSuccess: () => fetchData(search, sort, pagination),
      })}
      {continuousFetchInterval ? (
        <PollingFetch
          fetchData={() => fetchData(filters, sort, pagination)}
          continuousFetchInterval={continuousFetchInterval}
        />
      ) : null}
      <Card
        className={`${props.containerClass || ''} mb-4`}
        title={
          props.tabs ? (
            ''
          ) : (
            <span className='text-primary font-weight-bold'>
              {title ? titleize(title) : titleize(props.entity)}
            </span>
          )
        }
        extra={extra}
      >
        {(props.actions || props.tabs) && (
          <MobileAction>
            {props.actions}
            {button}
          </MobileAction>
        )}
        <AntdDataTable
          loading={loading}
          data={data}
          setData={setData}
          columns={columns}
          setColumns={setColumns}
          filtersState={{ filters, setFilters }}
          draw={draw}
          setDraw={setDraw}
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          setPagination={setPagination}
          total={total}
          setTotal={setTotal}
          fetchData={fetchData}
          handleChange={handleChange}
          responsive={true}
          rowKey={props.rowKey}
          highlightRow={props.highlightRow}
        />
      </Card>
    </>
  );
};

export default CRUDDataTable;
