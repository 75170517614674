import React, { useState, useEffect } from "react";
import {Button, Card, Modal, Space, Tag, Tabs, Tooltip} from "antd";
import TaskRejectionForm from "./TaskRejectionForm";
import {Link} from "react-router-dom";
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";
import {getTaskDatatable, updateStatus} from "../../api/tasks";
import PermissifiedComponent from "components/PermissifiedComponent";
import { useSelector } from 'react-redux';
import { isCurrentPermissionKeyPermitted } from "utils";

const Tasks = (props) => {
  const [rejectionVisible, setRejectionVisibility] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [entityId, setEntityId] = useState();
  const [status, setStatus] = useState('NEW');
  const {TabPane} = Tabs;
  const [modalMessage, setModalMessage] = useState('');
  const [permissifiedTabs, setPermissifiedTabs] = useState([
    {
        key: 1,
        status: 'NEW',
        permissionKey: 'tasks:new'
    },
    {
        key: 2,
        status: 'COMPLETED',
        permissionKey: 'tasks:completed'
    },
    {
        key: 3,
        status: 'REJECTED',
        permissionKey: 'tasks:rejected'
    }
  ]);

  // yes, confusing naming convention. will fix later sorry
  const [newStatus, setNewStatus] = useState('NEW');

  const permissionState = useSelector(state => state.permissions);

  const statusPermissions = [
      {
        status: 'NEW',
        permissionKey: 'tasks:new'
      },
      {
        status: 'COMPLETED',
        permissionKey: 'tasks:completed'
      },
      {
        status: 'REJECTED',
        permissionKey: 'tasks:rejected'
      }
  ]

    useEffect(() => {
        const newStatusPermissions = [];

        statusPermissions.map((statusPermission, index) => {
            if (isCurrentPermissionKeyPermitted(statusPermission.permissionKey, permissionState)) {
                statusPermission.key = (index + 1);
                newStatusPermissions.push(statusPermission);
            }
        });

        newStatusPermissions.length > 0 && setStatus(newStatusPermissions[0].status);
        setPermissifiedTabs(newStatusPermissions);
    }, []);

    const renderActionColumn = (activeTab) => {
        let actionColumn = null;

        if ((activeTab === 'NEW' && isCurrentPermissionKeyPermitted('tasks:new:options', permissionState))
        || (activeTab === 'COMPLETED' && isCurrentPermissionKeyPermitted('tasks:completed:options', permissionState))
        || (activeTab === 'REJECTED' && isCurrentPermissionKeyPermitted('tasks:rejected:options', permissionState))) {
            actionColumn = <strong>Actions</strong>;
        }

        return actionColumn;
    }

    const columns = [
        {
            title: <strong>Description</strong>,
            dataIndex: 'description',
            key: 'description',
            render: (text, record, value) => {
                let color = record.status === 'COMPLETED' ? 'green' : record.status === 'NEW' ? 'geekblue' : 'red';
                return <Tooltip title={record.rejectionReason}
                                style={{display: record.status === 'REJECTED' ? 'display' : 'none'}}>
                {text} <Tag color={color}>{record.status}</Tag><br/>
                <Link to={`appointments/${record?.appointment?.id}`}>{record?.appointment?.appointmentType}</Link>
                </Tooltip>
            }
        },
        {
            title: <strong>Assignee</strong>,
            dataIndex: 'assigneeType',
            key: 'assigneeType',
        },
        {
            title: () => renderActionColumn(status),
            dataIndex: 'id',
            key: 'id',
            render: (text, record, value) => {
                let buttons = (status) => {
                    switch (status) {
                        case 'NEW':
                            return isCurrentPermissionKeyPermitted('tasks:new:options', permissionState) && <>
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                setEntityId(text)
                                                setModalMessage('Mark task as Complete?')
                                                setNewStatus('COMPLETED')
                                                setConfirmationVisible(true)
                                                // showCompleteConfirmation(text)
                                            }}
                                        >
                                            Complete
                                        </Button>
                                        <Button danger onClick={() => {
                                            setRejectionVisibility(true)
                                            setEntityId(text);
                                        }}>
                                            Reject
                                        </Button>
                                </>
                        case 'COMPLETED':
                            return isCurrentPermissionKeyPermitted('tasks:completed:options', permissionState) && <Button onClick={() => {
                                        setEntityId(text)
                                        setModalMessage('Undo Completed Task?')

                                        setNewStatus('NEW')
                                        setConfirmationVisible(true)
                                        // showCompleteConfirmation(text)
                                    }}
                                    >
                                        Undo
                                    </Button>
                        case 'REJECTED':
                            return isCurrentPermissionKeyPermitted('tasks:rejected:options', permissionState) && <Button onClick={() => {
                                        setEntityId(text)
                                        setModalMessage('Undo Rejected Task?')
                                        setNewStatus('NEW')
                                        setConfirmationVisible(true)
                                        // showCompleteConfirmation(text)
                                    }}
                                    >
                                        Undo
                                    </Button>
                    }
                }

                return (
                    <Space>
                        {buttons(record.status)}
                    </Space>
                )
            }
        }
    ];
  const [draw, setDraw] = useState(0);
  const changeTab = key => {
    // setActiveTab(key)
    switch (key) {
      case '1':
        setStatus('NEW')
        setDraw(draw + 1);
        break;
      case '2':
        setStatus('COMPLETED')
        setDraw(draw + 1);
        break;
      case '3':
        setStatus('REJECTED')
        setDraw(draw + 1);
        break;
    }
  }
  return (<>
    <Modal
      visible={confirmationVisible}
      onCancel={() => setConfirmationVisible(false)}
      title={null}

      onOk={() => {
        // console.log(newStatus)
        updateStatus(entityId, {
          rejectionReason: '',
          status: newStatus
        })
          .then(res => {
            // console.log(res)
            setDraw(draw + 1)
            setConfirmationVisible(false);
          })

      }}
    >
      <strong>{modalMessage}</strong>
    </Modal>
    <TaskRejectionForm
        visible={rejectionVisible}
        setVisibility={setRejectionVisibility}
        id={entityId}
        onSuccess={() => {
            setDraw(draw + 1)
        }}
    />
    <Tabs
        onChange={changeTab}
    >
        {permissifiedTabs && permissifiedTabs.map(permissifiedTab => (
            <TabPane tab={permissifiedTab.status} key={permissifiedTab.key}>
                <CRUDDataTable
                    draw={draw}
                    entity="Tasks"
                    fetchDataFunction={async (params) => {
                        params.status = status;
                        return await getTaskDatatable(params);
                    }}
                    createModal={<></>}
                    columns={columns}
                    sortOrder="id"
                    button={<></>}
                />
            </TabPane>
        ))}
    </Tabs>
  </>)
}
export default Tasks