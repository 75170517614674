import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Footer from 'components/Layout/Footer';

import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumbsText } from 'redux/actions/ui';
import moment from 'moment';
import GitInfo from 'react-git-info/macro';

function BasePage(props) {
    const { pageName, children } = props;
    const [buildVersion, setBuildVersion] = useState(null);

    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();

    useEffect(()=>{
        return () => {
            dispatch(setBreadcrumbsText(''));
        }
    }, [dispatch]);

    useEffect(() => {
        const gitInfo = GitInfo();
        setBuildVersion(`${gitInfo.commit.shortHash}`);
    }, []);

    return (
        <>
            <div id='basePage'>
                {uiState.isTopbarVisible && (
                    <>
                        <Breadcrumbs />
                        <h1 className='fivef-pageTitle'>{pageName}</h1>
                    </>
                )}
                {children}
            </div>
            <Footer />
            <div className='text-right'>
                <small>Build version: <strong>{buildVersion}</strong></small>
            </div>
        </>
    );
}

export default BasePage;