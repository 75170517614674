import {SET_DATATABLE_FILTERS} from "./types/dataTable";

export const setDataTableFilters = (filters) => {
    let returnAction = {
        type: SET_DATATABLE_FILTERS
    }

    if(filters) {
        returnAction = {
            type: SET_DATATABLE_FILTERS,
            filters
        }
    }

    return returnAction;
}