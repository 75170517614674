import React, { useEffect, useRef, useState, FunctionComponent } from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import {
  ArrowUpOutlined,
  DeleteOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Spin, Tag } from 'antd';
import DeleteModal from "components/Modals/DeleteModal";
import ActionButtonsDropdown from "components/ActionButtonsDropdown";
import CreateMessageTemplateBroadcast from './CreateMessageTemplateBroadcast';
import CreateBroadcastModal from "./CreateBroadcastModal";
import { createBroadcast, getBroadcastDataTable, deleteBroadcast } from 'api/broadcasts';
import { useAppSelector } from "hooks/use-app-selector";
import { isCurrentPermissionKeyPermitted } from "utils";
import parse from 'html-react-parser';
import { 
    CgChevronDown,
    HiLocationMarker,
    HiAtSymbol,
    RiSendPlaneFill,
    BiErrorCircle
} from 'react-icons/all';
import SockJsClient  from 'react-stomp';
import { BASE_BACKEND_URL } from "api/config";

interface BroadcastsProps {}

enum MessageStatus {
    QUEUE = 'QUEUE',
    SENDING = 'SENDING',
    SENT = 'SENT',
    FAILED = 'FAILED'
};

const Broadcasts: FunctionComponent<BroadcastsProps> = (props) => {
    const [draw, setDraw] = useState(0);
    const [broadcastId, setBroadcastId] = useState(undefined);
    const [customMessageVisible, setCustomMessageVisibility] = useState(false);
    const [messageTemplateVisible, setMessageTemplateVisibility] = useState(false);
    const permissionState = useAppSelector(state => state.permissions);
    let sockJSRef:any = useRef(null);

    useEffect(() => {
        if (sockJSRef) {
            sockJSRef.connect();
        }
    }, [sockJSRef])

    const renderTagBasedOnStatus = (status: MessageStatus) => {
        switch(status) {
            case MessageStatus.FAILED: return <Tag color='error'>FAILED</Tag>
            case MessageStatus.SENDING: return <Tag color='lime'>SENDING</Tag>
            case MessageStatus.SENT: return <Tag color='green'>SENT</Tag>
            default: return <Tag color='default'>QUEUED</Tag>
        }
    };

    const columns = [
        {
            width: '50%',
            title: <strong>Broadcast</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
            ellipsis: true,
            render: (data: any, row: any) => {
                const IconStyle = {
                    color: '#fff',
                    fontSize: '1.5rem',
                    marginTop: '-3px'
                };

                const newMessageHTML = row.message?.replaceAll(/<p>|<\/p>/g, '');

                return <>
                            <h4 className='font-weight-bolder'>
                                <p>{data}</p>
                                <p className='my-3'>
                                    {row.location && <Tag color='#108ee9'><HiLocationMarker style={IconStyle} />&nbsp;{row.location}</Tag>}
                                    {row.recipientType && <Tag color='#00c853'><HiAtSymbol style={IconStyle} />&nbsp;{row.recipientType}</Tag>}
                                    {row.type && <Tag color='#ff9800'><RiSendPlaneFill style={IconStyle} />&nbsp;{row.type}</Tag>}
                                </p>
                            </h4>
                            <p>
                                <span
                                    className='font-weight-light text-ellipsis'
                                >
                                    {parse(newMessageHTML)}
                                </span>&nbsp;&nbsp;
                            </p>
                            {row?.errorMessage && <span className='text-danger'><BiErrorCircle fontSize='2.5rem' /> {row?.errorMessage}</span>}
                        </>
            }
        },
        {
            width: '10%',
            title: <strong>Status</strong>,
            dataIndex: "status",
            key: "status",
            sorter: true,
            render: (text, row, value) => {
                return (text ? renderTagBasedOnStatus(text) : null);
            }
        },
        {
            width: '20%',
            title: <strong>Date Sent</strong>,
            dataIndex: "dateSent",
            key: "dateSent",
            sorter: true,
        },
        {
            title: isCurrentPermissionKeyPermitted('broadcasts:options', permissionState) && <strong>Options</strong>,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text: any, record: any, value: any) => {
                const broadcastOptions: any = [
                    {
                        permissionKey: 'broadcasts:options:resend',
                        label: "Resend",
                        icon: <ArrowUpOutlined />,
                        onClick: async () => {
                            try {
                                const broadcastIdValue = record.id;

                                await createBroadcast({
                                    id: broadcastIdValue
                                }, '?resend=true');
                                setDraw(1);
                                message.success('Broadcast successfully resend', 2);
                            } catch (error) {
                                message.error('Broadcast resend failed', 2);
                            }
                        }
                    },
                    {
                        permissionKey: 'broadcasts:options:delete',
                        label: "Delete",
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            const broadcastIdValue = record.id;
                            setBroadcastId(broadcastIdValue)
                        },
                    }
                ];

                const permittedBroadcastOptions = broadcastOptions.filter((broadcastOption:any) => isCurrentPermissionKeyPermitted(broadcastOption.permissionKey, permissionState));

                return isCurrentPermissionKeyPermitted('broadcasts:options', permissionState) && <ActionButtonsDropdown
                    menus={permittedBroadcastOptions}
                />
            },
        },
    ];

    const handleMenuClick = (e:any) => {
        if (e.key === 'messageTemplate') {
            setMessageTemplateVisibility(true);
        } else {
            setCustomMessageVisibility(true);
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {isCurrentPermissionKeyPermitted('broadcasts:new:message_templates', permissionState) && <Menu.Item key='messageTemplate'>
                Message Template
            </Menu.Item>}
            {isCurrentPermissionKeyPermitted('broadcasts:new:custom_message', permissionState) && <Menu.Item key='custom'>
                Custom Message
            </Menu.Item>}
        </Menu>
    );

    return (
        <>
            <SockJsClient
                url={`${BASE_BACKEND_URL}/ws/message-invite`}
                topics={['/topic/history']}
                onMessage={(broadcast) => {
                    if (broadcast?.fetch) {
                        setDraw(draw + 1);
                    }
                }}
                onConnect={() => console.log('connected')}
                onConnectFailure={() => console.log('not connected')}
                ref={(client) => sockJSRef = client}
            />
            <DeleteModal
                entity="broadcast"
                visible={broadcastId}
                setVisibility={setBroadcastId}
                entityId={broadcastId}
                deleteFunction={() => deleteBroadcast(broadcastId)}
                onSuccess={() => setDraw(draw + 1)}
            />
            <CreateBroadcastModal
                visible={customMessageVisible}
                setVisibility={setCustomMessageVisibility}
                setDraw={setDraw}
                draw={draw} 
            />
            <CreateMessageTemplateBroadcast
                visible={messageTemplateVisible}
                setVisibility={setMessageTemplateVisibility}
                setDraw={setDraw}
                draw={draw} 
            />
            <CRUDDataTable
                draw={draw}
                entity="broadcast"
                fetchDataFunction={async (params: any) => {
                    params = {
                        ...params,
                        sortColumn: 'createdAt',
                        sortDirection: 'DESC',
                    }
                    return await getBroadcastDataTable(params);
                }}
                buttonPreventDefault={true}
                button={<Dropdown overlay={menu} trigger={['click']}>
                            <Button type='primary'>
                                New Broadcast&nbsp;&nbsp;<CgChevronDown />
                            </Button>
                        </Dropdown>}
                columns={columns}
                isButtonShown={isCurrentPermissionKeyPermitted('broadcasts:new', permissionState)}
            />
        </>
    );
};

export default Broadcasts;
