import React, { useEffect, useState } from 'react';
import Aux from 'components/hoc/AuxFunction';
import StatusModal from 'components/Appointments/Modals/StatusModal';
import { Alert } from 'components/Layout/Alert';
import { Col, Row } from 'react-bootstrap';
import {Link, useLocation, useParams} from 'react-router-dom';
import { Badge, DatePicker, Input, Menu, Select, Tooltip, Tag } from 'antd';
import {
  EditOutlined,
  FileAddOutlined,
  FileOutlined,
  FileTextOutlined,
  FileZipOutlined,
  MessageOutlined,
  PaperClipOutlined,
  ContainerTwoTone
} from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';
import CreateMessageModal from '../Appointment/components/Messages/CreateMessageModal';
import CreateNoteModal from '../Appointment/components/Notes/CreateNoteModal';
import CreateCollateralModal from '../Appointment/components/Collateral/CreateCollateralModal';
import moment from 'moment';
import { getAllLocations } from '../../api/locations';
import CRUDDataTable from '../../components/DataTable/CRUDDataTable';
import CreateVisitorForm from '../Visitors/components/CreateVisitorForm';
import { getAppointmentsDatatable } from '../../api/appointments';
import HospitalSelect from '../../components/location/hospitalSelect';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import { FcFile } from 'react-icons/fc';
import DocumentIcon from 'assets/icons/document-icon';
import MessageIcon from 'assets/icons/message-icon';
import ResourceIcon from 'assets/icons/resource-icon';
import AttachmentIcon from 'assets/icons/attachment-icon';
import PatientIcon from 'assets/icons/patient-icon';
import NurseIcon from 'assets/icons/nurse-icon';
import CreateFormModal from 'pages/Appointment/components/Forms/CreateFormModal';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import PermissifiedComponent from 'components/PermissifiedComponent';
import { isCurrentPermissionKeyPermitted, permissifyTableColumns } from 'utils';
import {setAppointmentListCustomFilters} from "redux/actions/appointments";
import {useHistory} from "react-router";

const AppointmentsList = (props) => {
    const dataTableCachedFilterKey = 'appointmentListFilter';
    const {
    setMessageModalVisibility,
    setCollateralModalVisibility,
    setNoteModalVisibility,
    setActiveAppointmentId,
    } = props;
    const permissionState = useSelector(state => state.permissions);
    const { RangePicker } = DatePicker;
    const [
        isCreateMessageModalVisible,
        setCreateMessageModalVisibility,
    ] = useState(false);
    const [isCreateNoteModalVisible, setCreateNoteModalVisibility] = useState(
        false
    );
    const [
        isCreateCollateralModalVisible,
        setCreateCollateralModalVisibility,
    ] = useState(false);
    const [isStatusModalVisible, setStatusModalVisibility] = useState(false);
    const [draw, setDraw] = useState(0);
    
    //const newFilter = [];
    //newFilter.push({ paramName: 'startDate', value: moment().format('yyyy-MM-DD') });
    //newFilter.push({ paramName: 'endDate', value: moment().format('yyyy-MM-DD') });
    const searchParams = new URLSearchParams(window.location.search);
    const cachedCustomFilters = useSelector((state) => state.appointmentListCustomFilter)
    const [customFilters, setCustomFilters] = useState(searchParams.get('filterDefaults') === 'true?'? [] : cachedCustomFilters);
    const [defaultHospitalSelectValue, setDefaultHospitalSelectValue] = useState(undefined);
    const history = useHistory();

    const [timeFilters, setTimeFilters] = useState([]);
    const dispatch = useDispatch();

    const [id, setId] = useState(0);
    const [hospitalOptions, setHospitalOptions] = useState([]);
    const [nameSearch, setNameSearch] = useState('');
    const satisfied = async () => {
        let response = await getAllLocations();
        // two nested data calls because it's returning a datatable
        // console.log(response.data.data)
        setHospitalOptions(response.data.data);
    };

    useEffect(() => {
        satisfied();
    }, []);

    useEffect(() => {
        dispatch(setAppointmentListCustomFilters(customFilters));
    }, [customFilters])

    useEffect(() => {
        if(searchParams.get('filterDefaults') !== 'true') {
            let id = cachedCustomFilters?.filter(f => f.paramName === 'locationId')[0]?.value;
            setDefaultHospitalSelectValue(id);
        } else {
            setDefaultHospitalSelectValue(undefined);
        }
    }, [hospitalOptions])

    //useEffect(() => {
    //    initFilters();
    //}, []);

    //const initFilters = () => {
    //    const newFilter = [...customFilters];

    //    newFilter.push({ paramName: 'startDate', value: moment().format('yyyy-MM-DD') });
    //    newFilter.push({ paramName: 'endDate', value: moment().format('yyyy-MM-DD') });

    //    setCustomFilters(newFilter);
    //}

  const [isCreateFormModalVisible, setCreateFormModalVisibility] = useState(false);

  const renderMenu = (id, appointmentTitle) => (
    <Menu>
      <Menu.Item>
        <Link
          to={`/appointments/${id}`}
          onClick={() => setActiveAppointmentId(id)}
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setStatusModalVisibility({
              id: id,
              isVisible: true,
            });
          }}
        >
          Change Status
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateMessageModalVisibility(true);
            setId(id);
          }}
        >
          Send Message
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateCollateralModalVisibility(true);
            setId(id);
          }}
        >
          Attach Collateral
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateNoteModalVisibility(true);
            setId(id);
          }}
        >
          Add Note
        </Link>
      </Menu.Item>
    </Menu>
  );

  const notificationTypes = [
    {
      name: 'newForms',
      icon: <DocumentIcon />,
    },
    {
      name: 'newMessages',
      icon: <MessageIcon />,
    },
    {
      name: 'newResources',
      icon: <ResourceIcon />,
    },
    {
      name: 'newAttachments',
      icon: <AttachmentIcon />,
    },
    {
      name: 'incompletePatientTasks',
      icon: <PatientIcon />,
    },
    {
      name: 'incompleteStaffTasks',
      icon: <ListAltTwoToneIcon />,
    },
  ];

  const columns = [
    {
        permissionKey: 'appointments:list:column:first_name',
        title: <strong>Patient</strong>,
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
        render: (text, record, index) => {
            return (
            <>
                <Link
                to={`/appointments/${record?.id}`}
                onClick={() => {
                    // setActiveAppointmentId(record?.id)
                    history.replace('/appointments')
                }}
                >
                {record?.firstName} {record?.lastName}
                </Link>
            </>
            );
        },
        filter: {
            paramName: 'search',
            label: 'Patient',
            // hidden: true,
            component: {
                name: 'text',
                placeholder: 'Enter patient name',
            },
        },
    },
    {
        permissionKey: 'appointments:list:column:date_time',
        title: <strong>Date / Time</strong>,
        dataIndex: 'appointmentDateTime',
        key: 'appointmentDateTime',
        sorter: true,
        render: (text, record, value) => moment(text).format('LLL'),
        filter: {
          paramName: 'date',
          label: 'Date',
          component: {
              name: 'daterange',
              defaultValue: [moment(), moment()]
          },
          defaultValue: [moment(), moment()],
          displayFormat: (value) => {
              const dates = value && value.map((date) => date.format('DD-MM-yyyy'));
              return `${dates[0]} to ${dates[1]}`;
          },
          beforeRequest: (value) =>
              value &&
              value
                  .map((date) => date.format('yyyy-MM-DD'))
                  .reduce((result, date, i) => {
                  if (i === 0) result.startDate = date;
                  else result.endDate = date;

                  return result;
              }, {}),
      },
    },
    {
        permissionKey: 'appointments:list:column:appointment_type',
        title: <strong>Appointment</strong>,
        dataIndex: 'appointmentType',
        key: 'appointmentType',
        sorter: true,
        render: (text, record, value) => {
            return (
                <>
                    <div>{record?.appointmentType}</div>
                    <Tag color={record?.attendanceType === 'CLINIC' ? 'blue' : 'green'}>{record?.attendanceType}</Tag>
                </>
            )
        },
        filter: {
            paramName: 'appointmentType',
            label: 'Appointment Type',
            component: {
                name: 'text',
                placeholder: 'Enter Appointment Type',
            },
        },
    },
    {
        permissionKey: 'appointments:list:column:clinician',
        title: <strong>Clinician</strong>,
        dataIndex: 'attendingDoctorName',
        key: 'attendingDoctorName',
        sorter: true,
    },
    {
      permissionKey: 'appointments:list:column:status',
      title: <strong>Status</strong>,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      filter: {
        paramName: 'status',
        label: 'Status',
        component: {
          name: 'select',
          placeholder: 'Select a status',
          fetchData: () => [
            { value: 'SCHEDULED', displayText: 'Scheduled' },
            { value: 'CONFIRMED', displayText: 'Confirmed' },
            { value: 'IN_PROGRESS', displayText: 'In Progress' },
            { value: 'COMPLETE', displayText: 'Complete' },
            { value: 'CANCELLED', displayText: 'Cancelled' },
            { value: 'DID_NOT_ATTEND', displayText: 'Did Not Attend' }
          ]
        }
      }
    },
    {
        permissionKey: 'appointments:list:column:options',
        title: <strong>Options</strong>,
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: (text, record, value) => {
            const appointmentOptions = [
                {
                    permissionKey: 'appointments:list:column:options:update_status',
                    label: 'Update Status',
                    icon: <EditOutlined />,
                    onClick: () => {
                        setStatusModalVisibility(true);
                        setId(record.id);
                    },
                },
                {
                    permissionKey: 'appointments:list:column:options:add_collateral',
                    label: 'Add Collateral',
                    icon: <PaperClipOutlined />,
                    onClick: () => {
                        setCreateCollateralModalVisibility(true);
                        setId(record.id);
                    },
                },
                {
                    permissionKey: 'appointments:list:column:options:send_message',
                    label: 'Send Message',
                    icon: <MessageOutlined />,
                    onClick: () => {
                        setCreateMessageModalVisibility(true);
                        setId(record.id);
                    },
                },
                {
                    permissionKey: 'appointments:list:column:options:add_note',
                    label: 'Add Note',
                    icon: <FileTextOutlined />,
                    onClick: () => {
                        setCreateNoteModalVisibility(true);
                        setId(record.id);
                    },
                },
                {
                    permissionKey: 'appointments:list:column:options:add_form',
                    label: 'Add Form',
                    icon: <FileOutlined />,
                    onClick: () => {
                        setCreateFormModalVisibility(true);
                        setId(record.id);
                    }
                }
            ];

            const permittedOptions = appointmentOptions.filter(appointmentOption => isCurrentPermissionKeyPermitted(appointmentOption.permissionKey, permissionState));

            return (
                <>
                    <ActionButtonsDropdown
                        menus={permittedOptions}
                    />
                </>
            );
        },
    },
    {
        permissionKey: 'appointments:list:column:actionables',
        title: <strong>Actionables</strong>,
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: (text, record, value) => {
            return (
            <div>
                {notificationTypes.map((type) =>
                    record[type.name] ? (
                        <Tooltip title={`${record[type.name]} ${type.name.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(' ')}`}>
                            <Badge count={record[type.name]}>
                                {React.cloneElement(type.icon, {
                                    width: 25,
                                    height: 25,
                                    className: 'm-1 actionables',
                                })}
                            </Badge>
                        </Tooltip>
                    ) : null
                )}
            </div>
            );
        },
    },
    {
        permissionKey: 'appointments:list:column:clinician',
        filter: {
            paramName: 'doctorName',
            label: 'Clinician',
            component: {
                name: 'text',
                placeholder: 'Enter clinician name',
            },
        },
    },
    {
        permissionKey: 'appointments:list:column:filter:span',
            filter: {
                paramName: 'span',
                label: 'Span',
                defaultValue: 'UPCOMING',
                component: {
                name: 'select',
                placeholder: 'Select span',
                fetchData: () => {
                    return [
                        { value: 'ALL', displayText: 'ALL' },
                        { value: 'PAST', displayText: 'PAST' },
                        { value: 'UPCOMING', displayText: 'UPCOMING' },
                    ];
                },
            },
        },
    },
];

  function onBlur() {
    // console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }

  const fetchAppointments = async(params) => {
    params = {
      ...params,
      sortColumn: 'appointmentDateTime'
    }

    if (params.date && Array.isArray(params.date)) {
      if (params.date[0]){
        params.startDate = params.date[0].format('yyyy-MM-DD');
      }
      if (params.date[1]){
        params.endDate = params.date[1].format('yyyy-MM-DD');
      }      
      delete params.date;
    }
    return await getAppointmentsDatatable(params);
  }

    return (
    <Aux>
      <StatusModal
        isStatusModalVisible={isStatusModalVisible}
        setStatusModalVisibility={setStatusModalVisibility}
        id={id}
        onSuccess={() => setDraw(draw + 1)}
      />
      <Alert />
      <CreateNoteModal
        visible={isCreateNoteModalVisible}
        setVisibility={setCreateNoteModalVisibility}
        icon={<FileTextOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateMessageModal
        visible={isCreateMessageModalVisible}
        setVisibility={setCreateMessageModalVisibility}
        icon={<MessageOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateCollateralModal
        visible={isCreateCollateralModalVisible}
        setVisibility={setCreateCollateralModalVisibility}
        icon={<PaperClipOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateFormModal
        visible={isCreateFormModalVisible}
        setVisibility={setCreateFormModalVisibility}
        icon={<FileOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <PermissifiedComponent permissionKey='appointments:location_filter'>
        <Row>
            <Col md='3'>
            <HospitalSelect
                width={{width: '275px'}}
                direction='horizontal'
                value={defaultHospitalSelectValue}
                onChange={(value) => {
                    // if(!value) return;
                const paramName = 'locationId';
                const filters = [...customFilters];
                const hospitalFilter = filters.filter(
                    (filter) => filter.paramName === paramName
                )[0];

                if (hospitalFilter) {
                    hospitalFilter.value = value;
                } else {
                    filters.push({ paramName: paramName, value: value });
                }

                setCustomFilters(filters);
                setDefaultHospitalSelectValue(value);
                }}
            />
            </Col>
        </Row>
        <br />
      </PermissifiedComponent>
      <CRUDDataTable
        draw={draw}
        entity='Appointments'
        fetchDataFunction={fetchAppointments}
        continuousFetchInterval={60000}
        customFilters={customFilters}
        cachedFilterEnabled={true}
        cachedFilterKey={dataTableCachedFilterKey}
        cachedFilterAsDefault={searchParams.get('filterDefaults') !== 'true'}
        createModal={<CreateVisitorForm />}
        columns={permissifyTableColumns(columns, permissionState)}
        sortOrder='appointmentDateTime'
        button={<></>}
        highlightRow={(rec, i) => {
          return (
            rec.highlightDeclinedForStaff ||
            rec.newForms > 0 ||
            rec.newMessages > 0 ||
            rec.newAttachments > 0 ||
            rec.incompleteStaffTasks > 0
          );
        }}
      />
    </Aux>
  );
};

export default AppointmentsList;
