import React, { useRef, useState, useEffect } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import DeleteModal from 'components/Modals/DeleteModal';
import {
  getCollateralsDatatableById,
  openAppointmentCollateralFile,
  deleteCollateralToAppointment,
} from 'api/appointmentCollaterals';
import CreateCollateralModal from './CreateCollateralModal';
import {
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import { Link } from 'react-router-dom';
import { isCurrentPermissionKeyPermitted } from 'utils';
import { useSelector } from 'react-redux';

const AppoinmentCollateral = ({ appointmentId }) => {
  const [draw, setDraw] = useState(0);
  const permissionState = useSelector((state) => state.permissions);
  const [collateralId, setCollateralId] = useState('');

  useEffect(
    (loadAppointmentCollaterals) => {
      setDraw(draw + 1);
    },
    [appointmentId]
  );

  const generateOptionsColumnHeader = () => {
    let optionsColumnHeader = null;

    if (
      isCurrentPermissionKeyPermitted(
        'appointments:collateral:list:columns:options',
        permissionState
      )
    ) {
      optionsColumnHeader = <strong>Options</strong>;
    }

    return optionsColumnHeader;
  };

  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text, record, value) => {
        return (
          <Link
            to={'#'}
            onClick={() =>
              openAppointmentCollateralFile(record.id).then((res) =>
                window.open(res.data)
              )
            }
          >
            {record.collateral.name}
          </Link>
        );
      },
    },
    {
      title: <strong>Description</strong>,
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      render: (text, record, value) => {
        return record.collateral.description;
      },
    },
    {
      title: generateOptionsColumnHeader(),
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        const collateralActions = [
          {
            permissionKey: 'appointments:collateral:options:download_resource',
            label: 'Download Resource',
            icon: <CloudDownloadOutlined />,
            onClick: () => {
              // window.open(`${BASE_URL}/appointment-collaterals/${record.id}/file`);
              openAppointmentCollateralFile(record.id).then((res) => {
                window.open(res.data);
              });
            },
          },
          {
            permissionKey: 'appointments:collateral:options:delete_resource',
            label: 'Delete Resource',
            icon: <DeleteOutlined />,
            onClick: () => setCollateralId(text),
          },
        ];

        const permittedCollateralActions = collateralActions.filter(
          (collateralAction) =>
            isCurrentPermissionKeyPermitted(
              collateralAction.permissionKey,
              permissionState
            )
        );

        return isCurrentPermissionKeyPermitted(
          'appointments:collateral:list:columns:options',
          permissionState
        ) ? (
          <ActionButtonsDropdown menus={permittedCollateralActions} />
        ) : null;
      },
    },
  ];

  return (
    <>
      <DeleteModal
        entity='resource'
        visible={collateralId}
        setVisibility={setCollateralId}
        entityId={collateralId}
        deleteFunction={deleteCollateralToAppointment}
        onSuccess={() => setDraw(draw + 1)}
      />
      <CRUDDataTable
        fetchDataFunction={async (params) => {
          return await getCollateralsDatatableById(appointmentId, params);
        }}
        title='Resources'
        entity='resource'
        createModal={
          <CreateCollateralModal
            appointmentId={appointmentId}
            icon={<PaperClipOutlined className='mr-2' />}
          />
        }
        columns={columns}
        isButtonShown={isCurrentPermissionKeyPermitted(
          'appointments:collateral:new',
          permissionState
        )}
        draw={draw}
      />
    </>
  );
};

export default AppoinmentCollateral;
