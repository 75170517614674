import { useState } from "react";
import { Button, Modal, message, Dropdown, Menu } from 'antd';
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import { deleteTemplateMessage, getMessageTemplatesDataTable } from "api/messageTemplates";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { setActiveMessageTemplate, setMessageTemplateMode } from "redux/actions/messageTemplates";
import { MessageTemplateMode } from "utils/enums";
import { isCurrentPermissionKeyPermitted } from "utils";

message.config({
  duration: 2,
});

const List = ({
    draw,
    setDraw
}) => {
    const [confirmationVisible, setConfirmationVisibility] = useState(false);
    const messageTemplateState = useSelector(state => state.messageTemplates);
    const permissionState = useSelector((state) => state.permissions);
    const dispatch = useDispatch();

    const handleMenuClick = (e, data) => {
        if (e.key === 'edit') {
            dispatch(setActiveMessageTemplate(data));
            dispatch(setMessageTemplateMode(MessageTemplateMode.EDIT))
        } else {
            dispatch(setActiveMessageTemplate(data));
            setConfirmationVisibility(true);
        }
    };

    const menu = (data) => (
        <Menu onClick={(e) => handleMenuClick(e, data)}>
            {isCurrentPermissionKeyPermitted('settings:message_templates:update', permissionState) && <Menu.Item key='edit'>
                Edit
            </Menu.Item>}
            {isCurrentPermissionKeyPermitted('settings:message_templates:delete', permissionState) && <Menu.Item key='delete'>
                Delete
            </Menu.Item>}
        </Menu>
    );

    const columns = [
        {
            width: '25%',
            title: <strong>Template Name</strong>,
            dataIndex: 'templateName',
            key: 'templateName',
            sorter: true,
            ellipsis: true,
        },
        {
            width: '55%',
            title: <strong>Subject</strong>,
            dataIndex: 'subject',
            key: 'subject',
            sorter: true,
            ellipsis: true,
            render: (text, record, value) => {
                const newMessageHTML = record?.message?.replaceAll(/<p>|<\/p>/g, '');

                return  <>
                            <p><strong>{record.subject}</strong></p>
                            {parse(newMessageHTML)}
                        </>
            }
        },
        {
            title: <strong>Options</strong>,
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            render: (text, record, value) => {
                return  <Dropdown overlay={menu(record)} trigger={['click']}>
                            <Button>
                                Select Option
                            </Button>
                        </Dropdown>
            }
        }
    ];

    const confirm = async () => {
        try {
            await deleteTemplateMessage(messageTemplateState.data.id);
            setConfirmationVisibility(false);
            setDraw(draw + 1);
            message.success('Message Template deleted successfully', 3);
        } catch (err) {
            setConfirmationVisibility(false);
            message.error('Message Template deletion failed', 3);
        }
    };

    return <>
        <Modal
            title="Confirmation"
            visible={confirmationVisible}
            onOk={confirm}
            onCancel={() => setConfirmationVisibility(false)}
            okText="Yes"
            cancelText="No"
        >
            Are you sure you want to remove this message template?
        </Modal>
        <CRUDDataTable
            containerClass='Message-Template-List'
            fetchDataFunction={async (params) => {
                params = {
                    ...params,
                    sortDirection: 'DESC',
                    sortOrder: 'lastModifiedAt',
                };
                const response = await getMessageTemplatesDataTable(
                    params
                );
                return response;
            }}
            title='List'
            entity='messageTemplates'
            columns={columns}
            createModal={null}
            isButtonShown={false}
            draw={draw}
        />
    </>;
};

export default List;
