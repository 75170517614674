import React, {useEffect, useState} from 'react';
import {getAllLocations, getLocationChildren, getLocation} from 'api/locations';
import {Row, Select, Space} from 'antd';

const HospitalSelect = props => {
  const {Option} = Select;
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [selectedParentHospital, setSelectedParentHospital] = useState();
  const [childValue, setChildValue] = useState();
  const [children, setChildren] = useState();
  const [parentValue, setParentValue] = useState();
  const useEntity = props.useEntity;
  const hideLabel = props.hideLabel;

  function fetchHospitalOptions() {
    (async () => {
      let response = await getAllLocations();
      setHospitalOptions(response.data.data)
    })();
  }

  useEffect(() => {
    fetchHospitalOptions();
  }, [])

  useEffect(() => {
    let location = props.defaultValue;
    // TODO not working as intended
    if (location !== undefined) {
      getLocation(location.id)
        .then(res => {
          let selectedLocation = res.data.data;
          if (!selectedLocation.parentId) {
            setParentValue(selectedLocation.id)
            onChange(selectedLocation.id)
          } else {
            setParentValue(selectedLocation.parentId);
            onChange(selectedLocation.id)
            setChildValue(selectedLocation.id)
          }

        })
    }
  }, [props.defaultValue])
  const [clinicOptions, setClinicOptions] = useState();

  const optionAll = props.removeAllOption
    ? ''
    : <Option value='ALL'>All</Option>

  const onChange = async value => {
    if (value === 'ALL') {
      if (clinicOptions !== undefined)
        setClinicOptions(undefined)
      props.onChange(undefined);
      return;
    }
    if (hospitalOptions.length === 0) {
      fetchHospitalOptions()

    }
    let hospital = hospitalOptions.find(hospital => hospital.id === value);
    if (hospital !== undefined) {
      let children = await getLocationChildren(hospital.id)
      let childrenTypeName = hospital.childrenTypeName;
      if (children.data.data.length > 0) {
        let newClinicOptions =
          <>
            <Row>
              {hideLabel ?? <>
                <label>{childrenTypeName ? childrenTypeName : 'Location'}:</label><br/></>}
              <Select
                // showSearch
                style={props.width ?? {width: '100%'}}
                placeholder='Select Location'
                optionFilterProp='children'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                size='large'
                value={childValue}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {children.data.data.map(item => {
                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                  }
                )}
                {optionAll}
              </Select>
            </Row>
          </>
        setClinicOptions(newClinicOptions)
      } else {
        setClinicOptions(null)
      }
    }
    if (hospital !== undefined && useEntity) {
      setSelectedParentHospital(hospital);
      props.onChange(hospital)
    } else if (hospital === undefined && selectedParentHospital !== undefined && useEntity) {
      let child = selectedParentHospital.children.find(hospital => hospital.id === value);
      props.onChange(child)
    } else if (useEntity) {
      let childHospital = hospitalOptions.find(hospital => {
        if (hospital.children) {
          let location = hospital.children.find(child => child.id === value)
          if (location !== undefined)
            return location;
        }
      })
      props.onChange(childHospital);
    } else {
      props.onChange(value);
    }

  }

  const onBlur = () => {
  }

  const onFocus = () => {
  }

  const onSearch = (val) => {
  }


  return (
    <>
      <Space direction={props.direction ?? 'vertical'} style={{width: '100%'}}>
        <div>
          {hideLabel ?? <><label>Hospital</label><br/></>}
          <Select
            // showSearch
            style={props.width ?? {width: '100%'}}
            placeholder='Select Location'
            optionFilterProp='children'
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            size='large'
            defaultValue={parentValue}
            value={props.value}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {hospitalOptions.map(item =>
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )}
            {optionAll}

          </Select>
        </div>
        {clinicOptions}
      </Space>
    </>
  )


}
export default HospitalSelect;
