export const dataTableReducer = (state = {}, action) => {
    switch(action.type) {
        case 'SET_DATATABLE_FILTERS':
            return {
                ...state,
                ...action.filters
            };
        default:
            return state;
    }
}