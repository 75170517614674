import React from "react";
import {
  Card,
  Input,
  Pagination,
  Tag,
  Table,
} from "antd";
import {Col, Row} from 'react-bootstrap';
import 'styles/DataTables.scss';
import moment from 'moment';

const {Search} = Input;

const AntdDataTable = ({
    rowKey, 
    data,
    columns, search, setSearch,
    pagination,
    setPagination,
    total,
    filtersState,
    handleChange,
    responsive,
    highlightRow,
    loading }) => {

    const { filters, setFilters } = filtersState ?? {};
    const hasValidFilter = filters? Object.values(filters).filter(v => v.value && v.label).length > 0 : false;
    const rowClassName = (rec, i) => {
        if(highlightRow) return highlightRow(rec, i) ? 'Crud-DataTable-Row-Highlight' : 'Crud-DataTable-Row';
        return '';
    }

  return (
    <>
      {responsive ? (
        <>
          <div className="d-none d-md-block">
            <Search
              placeholder="Search"
              className="mb-4"
              onChange={(e) => setSearch(e.currentTarget.value)}
              onSearch={(value, event) => setSearch(value)}
              value={search}
            />
              {
                  hasValidFilter && filters && Object.keys(filters).length > 0
                    ? <>
                        <strong>Filters: &nbsp;</strong>
                        {
                            Object.keys(filters).map( p => {
                              let newValTemp = '';
                                if (p && filters[p].label && filters[p].value) {
                                    let newVal = filters[p].value;
                                    if(filters[p].displayFormat) {
                                        newVal = filters[p].displayFormat(filters[p].value);
                                    }
                                    if (newVal && typeof newVal === "object" &&  Array.isArray(newVal)) {
                                      newVal.forEach((item, index) => {
                                        if (moment.isMoment(item)){
                                          newValTemp = newValTemp  +  item.format( 'DD-MM-yyyy') + (index < newVal.length -1 ? ' to ' : '');                                          
                                        }
                                      })

                                      newVal = newValTemp;
                                    } 
                                    return <Tag
                                        closable
                                        color='default'
                                        onClose={e => {
                                            const newFilter = {...filters}
                                            filters[p].value = null;
                                            setFilters(newFilter);
                                        }}
                                    >
                                        {`${filters[p].label}: ${newVal}`}
                                    </Tag>
                                } else return null;
                            })
                        }
                        <br/><br/>
                    </>
                    : null
              }
            <Table
              loading={loading}
              rowClassName={rowClassName}
              dataSource={data}
              columns={columns}
              onChange={handleChange}
              pagination={{
                ...pagination,
                total: total,
              }}
            />
          </div>
          <div className="d-block d-md-none">
            <div className="d-flex justify-content-center align-items-center mb-4">
              <Search
                placeholder="Search"
                onChange={(e) => setSearch(e.currentTarget.value)}
                onSearch={(value, event) => setSearch(value)}
              />
            </div>
              {data.map((d) => (
                  <Card bodyStyle={{padding: 0}} className={`mb-4 ${rowClassName(d, data.indexOf(d))}`}>
                      {columns.map((column) => (
                          <Row className="p-4">
                              <Col span={12}>{column.title}</Col>
                              <Col span={12}>{column.render ? column.render(d[column.dataIndex], d, 0) : d[column.dataIndex]}</Col>
                          </Row>
                      ))}
                  </Card>
              ))}
            <Pagination
              total={total}
              onChange={(current) =>
                setPagination({
                  ...pagination,
                  current,
                })
              }
            />
          </div>
        </>
      ) : (
        <>
          <Search
            placeholder="Search"
            className="mb-4"
            onChange={(e) => setSearch(e.currentTarget.value)}
            onSearch={(value, event) => setSearch(value)}
          />
          <Table
            loading={loading}
            rowClassName={rowClassName}
            onRow={ r => console.log('click')}
            dataSource={data}
            columns={columns}
            onChange={handleChange}
            pagination={{
              ...pagination,
              total: total
            }}
            rowKey={rowKey}
          />
        </>
      )}
    </>
  );
};

export default AntdDataTable;
