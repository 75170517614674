import React, { useState, useEffect } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {deleteAttachment, getAttachmentsDatatableById, getAttachmentUrl} from 'api/attachments';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import CreateAttachmentModal from './CreateAttachmentModal';
import { message, Tooltip, Button } from 'antd';
import fileDownload from 'js-file-download';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CreatePatientTaskModal from 'pages/Appointment/components/Tasks/CreatePatientTaskModal';
import { isCurrentPermissionKeyPermitted } from 'utils';
import { useSelector } from 'react-redux';
import DeleteModal from 'components/Modals/DeleteModal';

function downloadAttachment(record) {
  return async () => {
    const res = await getAttachmentUrl(record.id)
    window.open(res.data)
  };
}

const AppointmentAttachments = ({appointmentId, setRefreshTaskList}) => {
  const [ createPatientTaskVisible, setCreatePatientTaskVisibility ] = useState(false);
  const [ draw, setDraw ] = useState(0);
  const [ currentAttachmentId, setCurrentAttachmentId ] = useState(null);
  const permissionState = useSelector(state => state.permissions);

  const columns = [
    {
        title: <strong>Description</strong>,
        dataIndex: 'description',
        key: 'description',
        sorter: true,
        render: (text, record, value) => (
            <>
                <Tooltip title='Download Attachment'>
                    <span className='hover:cursor-pointer hover:text-primary mx-1' onClick={downloadAttachment(record)}>
                        {text}
                    </span>
                </Tooltip>
            </>
        ),
    },
    {
        title: isCurrentPermissionKeyPermitted('appointments:attachments:options', permissionState) && <strong>Options</strong>,
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: (text, record, value) => {
            const attachmentActions = [
                {
                    permissionKey: 'appointments:attachments:options:download',
                    label: 'Download Attachment',
                    icon: <CloudDownloadOutlined />,
                    onClick: downloadAttachment(record)
                },
                {
                    permissionKey: 'appointments:attachments:options:delete',
                    label: 'Delete Attachment',
                    icon: <DeleteOutlined />,
                    onClick: () => {
                        setCurrentAttachmentId(record?.id)
                    }
                }
            ];
    
            const permittedAttachmentActions = attachmentActions.filter(attachmentAction => isCurrentPermissionKeyPermitted(attachmentAction.permissionKey, permissionState));
    
            return isCurrentPermissionKeyPermitted('appointments:attachments:options', permissionState) && <ActionButtonsDropdown
                menus={permittedAttachmentActions}
            />
        },
    },
  ];

  useEffect(loadAppointmentAttachments => {
    setDraw(draw + 1);
  }, [appointmentId]);

  return (
    <>
        <DeleteModal
            entity="attachments"
            visible={currentAttachmentId}
            setVisibility={setCurrentAttachmentId}
            entityId={currentAttachmentId}
            deleteFunction={deleteAttachment}
            onSuccess={() => setDraw(draw + 1)}
        />
        <CreatePatientTaskModal 
            taskType='ATTACHMENT'
            visible={createPatientTaskVisible}
            setVisibility={setCreatePatientTaskVisibility}
            appointmentId={appointmentId} 
            icon={<UnorderedListOutlined className='mr-2' />} 
            onSuccess={() => { 
                setCreatePatientTaskVisibility(false); 
                setRefreshTaskList(true); 
            }}
            onCancel={()=> setCreatePatientTaskVisibility(false) }
        />
        <CRUDDataTable
            fetchDataFunction={async (params) => {
                return await getAttachmentsDatatableById(appointmentId, params);
            }}
            title='Attachments'
            entity='attachment'
            createModal={
            <CreateAttachmentModal
                appointmentId={appointmentId}
                icon={<PaperClipOutlined className='mr-2' />}
            />
            }
            columns={columns}
            draw={draw}
            isButtonShown={isCurrentPermissionKeyPermitted('appointments:attachments:new_attachment', permissionState)}
            actions={
                isCurrentPermissionKeyPermitted('appointments:attachments:new_attachment', permissionState) ? 
                <Button onClick={() => setCreatePatientTaskVisibility(true) }>Request Attachment</Button>
                : null
            }
        />
    </>
  );
};

export default AppointmentAttachments;
