import { Tooltip, Button, Space, Modal, Switch, Row, Col, Tree, Divider } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {
  deleteLocation,
  getLocationPreferences,
  getLocationTree,
  updateLocationPreferences,
} from "api/locations";
import DeleteModal from "components/Modals/DeleteModal";
import React, { useState } from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import CreateCollateralForm from "../Collateral/components/CreateCollateralForm";
import { Link, useParams } from "react-router-dom";
import LocationForm from "./components/LocationForm";
import ActionButtonsDropdown from "components/ActionButtonsDropdown";
import { isCurrentPermissionKeyPermitted } from "utils";
import { useSelector } from "react-redux";
import { Badge } from 'react-bootstrap';

const Locations = (props) => {
  const { id } = useParams();
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState("");
  const [locationId, setLocationId] = useState();
  const [parentId, setParentId] = useState();
  const [draw, setDraw] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(() => {});
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [preferencesModalVisible, setPreferencesModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSMS, setIsSMS] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const permissionState = useSelector((state) => state.permissions);
  const cols = [
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value, record, index) => {
        let statusBadge = null;

        if(record?.status){
          if (record?.status.toLowerCase() === 'active') {
            statusBadge = <Badge variant='success'>Active</Badge>
          }

          if (record?.status.toLowerCase() === 'inactive') {
              statusBadge = <Badge variant='danger'>Inactive</Badge>
          }
        }

        return  <>
                    {record?.name} {statusBadge}
                </>;
      }
        /*    render: (text, record, value) => {
                return record?.childrenAllowed
                    ? <Link to={`/locations/${record?.id}`}>{text}</Link>
                    : text
            }*/
    },
    {
      title: <strong>Email | ContactNumber</strong>,
      dataIndex: 'contact',
      key: 'contact',
      sorter: false,
      render: (text, record, value) => {
        let email = record?.email ? <a href={`mailto:${record?.email}`}>{record?.email}</a>:null;
        let contactNumber = record?.contactNumber? `${record?.contactNumber}`:null;
        return <>
          {email} {email && contactNumber?' | ':null} {contactNumber}
        </>;
      }
    },
    {
      title: isCurrentPermissionKeyPermitted(
        "locations",
        permissionState
      ) ? (
        <strong>Options</strong>
      ) : null,
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text, record, value) => {
        const locationActionOptions = [
          {
            permissionKey: "locations:options:update",
            label: "Update Location",
            icon: <EditOutlined />,
            onClick: () => {
              setLocationId(text);
              setSelectedLocation(record);
              setEditModalVisible(true);
            },
          },
          {
            permissionKey: "locations:options:delete",
            label: "Delete Location",
            icon: <DeleteOutlined />,
            onClick: () => {
              setDeleteModalVisibility(text);
              setLocationId(text);
            },
          },
        ];

        const permittedLocationActionOptions = locationActionOptions.filter(
          (locationActionOption) =>
            isCurrentPermissionKeyPermitted(
              locationActionOption.permissionKey,
              permissionState
            )
        );
        let buttons = () => {
          return (
            <>
              {" "}
              {record?.children?.length > 0 ? (
                ""
              ) : (
                <Tooltip title="Set Preferences">
                  <Button
                    type="primary"
                    onClick={() => {
                      setLocationId(text);
                      getPreferences(text);
                      setParentId(record.parentId);
                      setModalMessage("Set Preferences");
                      setIsParent(record?.parentId ? false : true);
                    }}
                  >
                    <MailOutlineIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}
            </>
          );
        };

        return <Row>
         {isCurrentPermissionKeyPermitted(
            "locations:options",
            permissionState
          ) && <ActionButtonsDropdown menus={permittedLocationActionOptions} />}
          {isCurrentPermissionKeyPermitted(
            "locations:preferences",
            permissionState
          ) && <Space>{buttons()}</Space>}
        </Row>
      },
    },
  ];

  const getPreferences = async (id) => {
    let res = await getLocationPreferences(id);
    setIsEmail(res.data.data.emailEnabled);
    setIsSMS(res.data.data.smsEnabled);
    setPreferencesModalVisible(true);
    return res;
  };

  const updatePreferences = async () => {
    let data = {};
    if (isParent) {
      data = {
        id: locationId,
        emailEnabled: isEmail,
        smsEnabled: isSMS,
        children: [],
      };
    } else {
      data = {
        id: parentId,
        isEmailEnabled: false,
        isSMSEnabled: false,
        children: [{
          id: locationId,
          emailEnabled: isEmail,
          smsEnabled: isSMS,
        }],
      };
    }
    let res = await updateLocationPreferences(data);
    setParentId(0);
    setPreferencesModalVisible(false);
    return res;
  };

  return (
    <>
      <Modal
        visible={preferencesModalVisible}
        onCancel={() => setPreferencesModalVisible(false)}
        title={modalMessage}
        okText="Save"
        onOk={() => {
          updatePreferences(locationId);
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className='font-weight-bolder'>SMS SENDING ENABLED</h4>
            <small>Check if you want to set the SMS Preferences.</small>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-right'>
            <Switch
              className='pull-right'
              style={{
                marginTop: '10px'
              }}
              checked={isSMS}
              onChange={(value) => {
                setIsSMS(value);
              }}
            />
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className='font-weight-bolder'>EMAIL SENDING ENABLED</h4>
            <small>Check if you want to set the Email Preferences.</small>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-right'>
            <Switch
              className='pull-right'
              style={{
                marginTop: '10px'
              }}
              checked={isEmail}
              onChange={(value) => {
                setIsEmail(value);
              }}
            />
          </Col>
        </Row>
      </Modal>
      <DeleteModal
        entity="location"
        visible={isDeleteModalVisible}
        setVisibility={setDeleteModalVisibility}
        entityId={locationId}
        deleteFunction={deleteLocation}
        onSuccess={() => setDraw(draw + 1)}
      />
      <LocationForm
        onSuccess={() => setDraw(draw + 1)}
        visible={isEditModalVisible}
        setVisibility={setEditModalVisible}
        locationId={locationId}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />
      <CRUDDataTable
        draw={draw}
        entity="location"
        fetchDataFunction={async (params) => {
          // if (id!==undefined)
          //     return await getLocationChildren(id);
          let res = await getLocationTree();
          addKeys(res.data.data);
          return res;
        }}
        createModal={<LocationForm />}
        columns={cols}
        isButtonShown={isCurrentPermissionKeyPermitted(
          "locations:new",
          permissionState
        )}
        rowKey="id" // this SUPPOSEDLY should be the solution, but it doesn't work.
      />
    </>
  );
};

/**
 * Reason for this is because upon further investigation,
 * the expandable tree in ant table is actually reliant on
 * them having "keys" though i'm not sure as to how to make
 * it reliant on the provided id instead, for now this is a temporary hack
 * @param entry
 * @param i
 */
const addKeys = (entry, i = 0) => {
  entry.forEach((item, index) => {
    item.key = i++;
    if (item.children.length > 0) {
      addKeys(item.children, i);
    } else {
      delete item.children;
    }
  });
};

export default Locations;
